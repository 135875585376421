import { gql, useApolloClient, useSuspenseQuery } from '@apollo/client';
import clsx from 'clsx';
import { Button } from 'components/button';
import { Copyable } from 'components/copyable';
import { Dropdown } from 'components/dropdown-v7';
import { Input } from 'components/input';
import { Tag } from 'components/tag';
import { TextArea } from 'components/text-area';
import {
  ActionsPageActivateActionMutation,
  ActionsPageActivateActionMutationVariables,
  ActionsPageDeactivateActionMutation,
  ActionsPageDeactivateActionMutationVariables,
  ActionsPageCreateActionMutation,
  ActionsPageCreateActionMutationVariables,
  ActionsPagePrioritizeMutation,
  ActionsPagePrioritizeMutationVariables,
  ActionsPageQuery,
  ActionsPageQueryVariables,
  ActionsPageUpdateActionMutation,
  ActionsPageUpdateActionMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { useCallback, useEffect, useState, useTransition } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { FaBars, FaPlus } from 'react-icons/fa';
import { KeyBindingMap, tinykeys } from 'tinykeys';
import { Colors } from 'utils/misc';
import { usePrevious } from 'utils/use-previous';
import { config } from 'config';
import { useUrlQuery } from 'utils/use-url-query';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';

const focusedActionFragment = gql`
  fragment FocusedAction on Action {
    id
    name
    ref
    description
    status
    priority
    titleTemplate
    urlTemplate
    createdAt
    updatedAt
    dependentActions {
      id
      name
    }
    objectives {
      id
    }
    prerequisiteActions {
      id
      name
    }
  }
`;

const queryDoc = gql`
  query ActionsPage($actionId: ID!, $actionFocused: Boolean!) {
    action(id: $actionId) @include(if: $actionFocused) {
      ...FocusedAction
    }
    actions {
      id
      name
      status
      priority
    }
    objectives {
      id
      name
    }
  }
  ${focusedActionFragment}
`;

export default function ActionsPage() {
  const notify = useNotifications();
  const [, startTransition] = useTransition();
  const [actionStatusChanging, setActionStatusChanging] = useState(false);
  const client = useApolloClient();
  const [search, setSearch] = useState('');
  const history = useHistory();
  const actionId = useUrlQuery().get('action') ?? '';

  const focusAction = useCallback(
    (id: string) => {
      if (id !== actionId) {
        startTransition(() => {
          history.push(`${routes.actions}?action=${id}`);
        });
      }
    },
    [actionId, history],
  );

  const form = useForm<{
    id: string; // If empty, assume create mode
    name: string;
    ref: string;
    description: string;
    titleTemplate: string;
    prerequisiteActionIds: string[];
    objectiveIds: string[];
    urlTemplate: string;
  }>();

  const query = useSuspenseQuery<ActionsPageQuery>(queryDoc, {
    variables: {
      actionId,
      actionFocused: !!actionId,
    },
  });

  const action = query.data.action;
  const prevAction = usePrevious(action);
  useEffect(() => {
    if (action?.id !== prevAction?.id) {
      form.reset({
        id: action?.id ?? '',
        name: action?.name ?? '',
        ref: action?.ref ?? '',
        objectiveIds: action?.objectives?.map((o) => o.id),
        description: action?.description ?? '',
        titleTemplate: action?.titleTemplate ?? '',
        prerequisiteActionIds: action?.prerequisiteActions?.map((a) => a.id),
        urlTemplate: action?.urlTemplate ?? '',
      });
    }
  }, [action, form, prevAction]);

  const filteredActions = query.data.actions?.filter((a) =>
    a.name.toLowerCase().includes(search.toLowerCase()),
  );

  let urlPlaceholder = 'URL';
  switch (config.brand) {
    case 'compound-uk':
      urlPlaceholder = 'compound://...';
      break;
    case 'juniper-uk':
    case 'juniper':
      urlPlaceholder = 'juniper://...';
      break;
  }

  useEffect(() => {
    const map: KeyBindingMap = {};
    if (filteredActions?.length) {
      map['Control+n'] = () => focusAction('');
      map['Control+j'] = () => focusAction(filteredActions?.at(0)?.id ?? '');
      map['Control+k'] = () => focusAction(filteredActions?.at(-1)?.id ?? '');
      for (const [idx, a] of filteredActions.entries()) {
        if (a.id === action?.id) {
          if (idx > 0) {
            const prev = filteredActions?.at(idx - 1)?.id;
            map['Control+k'] = () => focusAction(prev ?? '');
          }
          if (idx < filteredActions.length - 1) {
            const next = filteredActions?.at(idx + 1)?.id;
            map['Control+j'] = () => focusAction(next ?? '');
          }
        }
      }
    }

    return tinykeys(window, map);
  }, [filteredActions, action?.id, focusAction]);

  return (
    <DragDropContext
      onDragEnd={async (res) => {
        if (!res.destination) {
          return;
        }

        if (!query.data.actions?.length) {
          return;
        }

        let actions = query.data.actions.slice();
        const a = actions.splice(res.source.index, 1)[0];
        actions.splice(res.destination.index, 0, a);
        const incrementSize = 1 / actions.length;

        // Eagerly write new ordering
        client.cache.writeQuery<ActionsPageQuery, ActionsPageQueryVariables>({
          query: queryDoc,
          data: { ...query.data, actions, action },
          variables: {
            actionFocused: !!action?.id,
            actionId: action?.id ?? '',
          },
        });

        try {
          const resp = await client.mutate<
            ActionsPagePrioritizeMutation,
            ActionsPagePrioritizeMutationVariables
          >({
            mutation: gql`
              mutation ActionsPagePrioritize($input: PrioritizeActionsInput!) {
                prioritizeActions(input: $input) {
                  actions {
                    id
                    name
                    status
                    priority
                  }
                }
              }
            `,
            variables: {
              input: {
                actionPriorities: actions.map((a, idx) => ({
                  id: a.id,
                  priority: 1 - idx * incrementSize,
                })),
              },
            },
          });

          actions = resp.data?.prioritizeActions?.actions ?? [];
        } catch {
          actions = query.data.actions ?? [];
        } finally {
          // Either write the successful new ordering, or revert to what we had
          // before our eager write.
          client.cache.writeQuery<ActionsPageQuery, ActionsPageQueryVariables>({
            query: queryDoc,
            data: {
              ...query.data,
              actions,
              action,
            },
            variables: {
              actionFocused: !!action?.id,
              actionId: action?.id ?? '',
            },
          });
        }
      }}
    >
      <div className="flex min-h-full w-full gap-4 text-slate-700">
        {/* Actions List */}
        <div className="min-w-[360px] max-w-[360px] flex-1 flex flex-col gap-2">
          <Input
            name="search"
            autoFocus={true}
            value={search}
            placeholder="Search..."
            onChange={(evt) => setSearch(evt.target.value)}
          />

          <Droppable droppableId="actions">
            {(drop) => (
              <div {...drop.droppableProps} ref={drop.innerRef} className="">
                {filteredActions?.map((a, idx) => {
                  const selected = a.id === actionId;

                  let statusText = 'Unknown';
                  let statusColor: Colors = 'gray';
                  switch (a.status) {
                    case 'INACTIVE':
                      statusText = 'Inactive';
                      statusColor = 'orange';
                      break;
                    case 'ACTIVE':
                      statusText = 'Active';
                      statusColor = 'green';
                      break;
                  }

                  return (
                    <Draggable
                      key={a.id}
                      index={idx}
                      draggableId={a.id}
                      isDragDisabled={!!search}
                    >
                      {(drag) => (
                        <div
                          {...drag.draggableProps}
                          ref={drag.innerRef}
                          onClick={() => focusAction(a.id)}
                          className={clsx(
                            'group select-none bg-white py-3 px-4 border border-slate-200 shadow rounded-md cursor-pointer hover:shadow-md mb-2 transition-shadow',
                            selected && 'font-medium text-slate-800',
                          )}
                        >
                          <div className="w-full items-center flex justify-between">
                            <div className="flex gap-2 items-center flex-1 min-w-0">
                              <div {...drag.dragHandleProps}>
                                <FaBars
                                  className={clsx(
                                    'text-xs transition-colors',
                                    search
                                      ? 'text-slate-200 cursor-not-allowed'
                                      : 'text-slate-400 hover:text-slate-700',
                                  )}
                                />
                              </div>
                              <div className="truncate">{a.name}</div>
                            </div>
                            <div className="relative">
                              <div className="absolute right-0 group-hover:opacity-0 opacity-100 transition-opacity duration-300">
                                <Tag size="small" color={statusColor}>
                                  {statusText}
                                </Tag>
                              </div>
                              {a.status === 'INACTIVE' && (
                                <div className="group-hover:opacity-100 opacity-0 transition-opacity duration-300">
                                  <Button
                                    variant="text"
                                    size="small"
                                    loading={actionStatusChanging}
                                    onClick={(evt) => {
                                      evt.stopPropagation();

                                      setActionStatusChanging(true);
                                      client
                                        .mutate<
                                          ActionsPageActivateActionMutation,
                                          ActionsPageActivateActionMutationVariables
                                        >({
                                          mutation: gql`
                                            mutation ActionsPageActivateAction(
                                              $id: ID!
                                            ) {
                                              activateAction(
                                                input: { id: $id }
                                              ) {
                                                action {
                                                  id
                                                  status
                                                }
                                              }
                                            }
                                          `,
                                          variables: { id: a.id ?? '' },
                                        })
                                        .finally(() =>
                                          setActionStatusChanging(false),
                                        );
                                    }}
                                  >
                                    Activate
                                  </Button>
                                </div>
                              )}
                              {a.status === 'ACTIVE' && (
                                <div className="group-hover:opacity-100 opacity-0 transition-opacity duration-300">
                                  <Button
                                    variant="text"
                                    size="small"
                                    loading={actionStatusChanging}
                                    onClick={(evt) => {
                                      evt.stopPropagation();

                                      setActionStatusChanging(true);
                                      client
                                        .mutate<
                                          ActionsPageDeactivateActionMutation,
                                          ActionsPageDeactivateActionMutationVariables
                                        >({
                                          mutation: gql`
                                            mutation ActionsPageDeactivateAction(
                                              $id: ID!
                                            ) {
                                              deactivateAction(
                                                input: { id: $id }
                                              ) {
                                                action {
                                                  id
                                                  status
                                                }
                                              }
                                            }
                                          `,
                                          variables: { id: a.id ?? '' },
                                        })
                                        .finally(() =>
                                          setActionStatusChanging(false),
                                        );
                                    }}
                                  >
                                    Deactivate
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {drop.placeholder}
              </div>
            )}
          </Droppable>

          <div
            onClick={() => focusAction('')}
            className={clsx(
              'select-none active:border-slate-400 text-slate-500 bg-slate-100 font-medium py-3 px-4 border border-slate-300 shadow rounded-md cursor-pointer hover:shadow-md transition-shadow',
              !action?.id && 'font-medium',
              // Accounts for the bottom on the last action in the list.
              //
              // We can't use space-y-* or flex with gap-* etc because those
              // properties are calculated post-drop on react-beautiful-dnd so
              // there is jankiness when the item is dropped.
              filteredActions?.length && '-mt-2',
            )}
          >
            <div className="flex items-center gap-3">
              <FaPlus className="text-xs" />
              Create new action
            </div>
          </div>

          <p className="text-xs text-slate-400 mt-1 text-center">
            {search
              ? 'Clear filters to enable ranking actions.'
              : 'Drag and drop the above actions to change their relative priority.'}
          </p>
        </div>

        {/* Action Focus */}
        <div
          className={clsx(
            'bg-white shadow border border-slate-200 rounded-md flex flex-col flex-1 overflow-hidden',
          )}
        >
          <div className="w-full flex justify-between items-center bg-slate-50 px-5 py-4 border-b border-slate-200">
            <h1 className="font-semibold">{action?.name ?? 'New Action'}</h1>
            <Copyable text={action?.id ?? ''}>
              {(copied) => (
                <pre className="text-sm cursor-pointer text-slate-500">
                  {copied ? 'Copied' : action?.id.slice(-6)}
                </pre>
              )}
            </Copyable>
          </div>
          <div className="px-5 py-5 flex flex-1">
            <form
              className="flex-1 flex flex-col gap-5"
              onSubmit={form.handleSubmit(async (data) => {
                let action;
                if (data.id) {
                  const resp = await client.mutate<
                    ActionsPageUpdateActionMutation,
                    ActionsPageUpdateActionMutationVariables
                  >({
                    mutation: gql`
                      mutation ActionsPageUpdateAction(
                        $input: UpdateActionInput!
                      ) {
                        updateAction(input: $input) {
                          action {
                            ...FocusedAction
                          }
                        }
                      }
                      ${focusedActionFragment}
                    `,
                    variables: {
                      input: {
                        id: data.id,
                        description: data.description,
                        ref: data.ref,
                        titleTemplate: data.titleTemplate,
                        name: data.name,
                        urlTemplate: data.urlTemplate,
                        prerequisiteActionIds: data.prerequisiteActionIds,
                      },
                    },
                  });

                  notify({ message: 'Action Updated', type: 'success' });
                  action = resp.data?.updateAction?.action;
                }

                if (!data.id) {
                  const resp = await client.mutate<
                    ActionsPageCreateActionMutation,
                    ActionsPageCreateActionMutationVariables
                  >({
                    mutation: gql`
                      mutation ActionsPageCreateAction(
                        $input: CreateActionInput!
                      ) {
                        createAction(input: $input) {
                          action {
                            ...FocusedAction
                          }
                        }
                      }
                      ${focusedActionFragment}
                    `,
                    variables: {
                      input: {
                        description: data.description,
                        titleTemplate: data.titleTemplate,
                        name: data.name,
                        objectiveIds: data.objectiveIds,
                        ref: data.ref,
                        urlTemplate: data.urlTemplate,
                        prerequisiteActionIds: data.prerequisiteActionIds,
                      },
                    },
                  });

                  notify({ message: 'Action Created', type: 'success' });
                  action = resp.data?.createAction?.action;
                }

                if (action) {
                  const actions = query.data.actions?.slice() ?? [];
                  if (!data.id) {
                    actions?.unshift(action);
                  }

                  client.cache.writeQuery<
                    ActionsPageQuery,
                    ActionsPageQueryVariables
                  >({
                    query: queryDoc,
                    data: {
                      ...query.data,
                      actions,
                      action,
                    },
                    variables: {
                      actionFocused: true,
                      actionId: action.id,
                    },
                  });

                  focusAction(action.id);
                }
              })}
            >
              <input hidden={true} {...form.register('id')} />
              <div className="flex gap-2">
                <div className="flex-1">
                  <Input
                    {...form.register('name', { required: true })}
                    label="Name"
                    placeholder="Enter an internal name..."
                    errorMessage={form.formState.errors.name?.message}
                  />
                </div>
                <div className="flex-1">
                  <Input
                    {...form.register('ref', {
                      required: true,
                      validate(v) {
                        if (/^[a-z]+(?:_[a-z0-9]+)*$/.test(v)) {
                          return true;
                        }
                        return 'Ref must be lower_snake_case';
                      },
                    })}
                    label="Ref"
                    placeholder="Enter an internal reference..."
                    errorMessage={form.formState.errors.ref?.message}
                  />
                </div>
              </div>
              <TextArea
                {...form.register('description', { required: true })}
                label="Description"
                placeholder="Enter an internal description..."
                rows={5}
                errorMessage={form.formState.errors.description?.message}
              />
              <Input
                {...form.register('titleTemplate', { required: true })}
                label="Title Template"
                placeholder="Enter a customer-facing title..."
                errorMessage={form.formState.errors.titleTemplate?.message}
              />
              <Input
                {...form.register('urlTemplate', { required: true })}
                label="URL Template"
                placeholder={urlPlaceholder}
                errorMessage={form.formState.errors.urlTemplate?.message}
              />
              <Dropdown
                label="Objectives"
                name="objectiveIds"
                placeholder="Enter related objectives..."
                isMulti={true}
                menuPlacement="auto"
                control={form.control}
                options={
                  query.data.objectives?.map((a) => ({
                    label: a.name,
                    value: a.id,
                  })) ?? []
                }
              />
              <Dropdown
                label="Prerequisite Actions"
                name="prerequisiteActionIds"
                placeholder="Enter prerequisite actions..."
                isMulti={true}
                menuPlacement="auto"
                control={form.control}
                options={
                  query.data.actions
                    ?.filter((a) => a.id !== action?.id)
                    .map((a) => ({ label: a.name, value: a.id })) ?? []
                }
              />
              <div className="flex-1 flex items-end gap-2 justify-end text-nowrap">
                <Button
                  disabled={!form.formState.isDirty}
                  variant="outline"
                  onClick={() => form.reset()}
                >
                  Discard Changes
                </Button>
                <Button
                  type="submit"
                  loading={form.formState.isSubmitting}
                  disabled={!form.formState.isDirty}
                >
                  {action?.id ? 'Update' : 'Create'}
                </Button>
              </div>
            </form>
            <div
              className={clsx(
                'hidden xl:block w-0.5 bg-slate-200 mx-6 [mask-image:linear-gradient(to_bottom,transparent_0%,black_5%,black_95%,transparent_100%)]',
                !action && 'xl:hidden',
              )}
            />
            <div
              className={clsx(
                'hidden xl:block max-w-[250px] flex-1 text-slate-600 text-sm space-y-4',
                !action && 'xl:hidden',
              )}
            >
              <div>
                <div className="heading-sm mb-1.5">Created</div>
                {action?.createdAt
                  ? new Date(action.createdAt).toLocaleString()
                  : '—'}
              </div>

              <div>
                <div className="heading-sm mb-1.5">Last Updated</div>
                <div>
                  {action?.updatedAt
                    ? new Date(action.updatedAt).toLocaleString()
                    : '—'}
                </div>
              </div>

              <div>
                <div className="heading-sm mb-1.5">Priority</div>
                <div>{action?.priority ?? 0}</div>
              </div>

              <div>
                <div className="heading-sm mb-1.5">Dependants</div>
                <div className="flex flex-col gap-1.5">
                  <div
                    className={action?.dependentActions?.length ? 'hidden' : ''}
                  >
                    None
                  </div>
                  {action?.dependentActions?.map((a) => (
                    <button
                      className="text-left shadow-sm px-3 py-2 rounded bg-slate-50 border border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600 font-medium text-xs hover:scale-[101%] transition-all"
                      onClick={() => focusAction(a.id)}
                      key={a.id}
                    >
                      {a.name}
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <div className="heading-sm mb-1.5">Depends On</div>
                <div className="flex flex-col gap-1.5">
                  <div
                    className={
                      action?.prerequisiteActions?.length ? 'hidden' : ''
                    }
                  >
                    None
                  </div>
                  {action?.prerequisiteActions?.map((a) => (
                    <button
                      className="text-left shadow-sm px-3 py-2 rounded bg-slate-50 border border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600 font-medium text-xs hover:scale-[101%] transition-all"
                      onClick={() => focusAction(a.id)}
                      key={a.id}
                    >
                      {a.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}
