import React, { useState } from 'react';
import {
  ArchiveIhiMutation,
  ArchiveIhiMutationVariables,
  RevalidateIndividualHealthcareIdentifierMutation,
  RevalidateIndividualHealthcareIdentifierMutationVariables,
} from 'graphql/types';
import { FaRedo } from 'react-icons/fa';
import { Loading } from '../../components/loading';
import { useNotifications } from 'notifications';
import { formatDateAndTimeSeconds } from 'utils/misc';
import { gql, useMutation } from '@apollo/client';
import { Customer } from './types';
import { Button, ProtectedButton } from 'components/button';
import { Modal } from 'components/modal';

export const IndividualHealthcareIdentifierSection = ({
  customer,
  refetchCustomer,
}: {
  customer: Customer;
  refetchCustomer: () => void;
}): React.ReactElement => {
  const [showConfirm, setShowConfirm] = useState(false);
  const showNotification = useNotifications();

  const [revalidateIhiMutation, { loading: loadingRevalidateIhi }] =
    useMutation<
      RevalidateIndividualHealthcareIdentifierMutation,
      RevalidateIndividualHealthcareIdentifierMutationVariables
    >(
      gql`
        mutation RevalidateIndividualHealthcareIdentifier(
          $input: RevalidateIndividualHealthcareIdentifierInput!
        ) {
          revalidateIndividualHealthcareIdentifier(input: $input) {
            customer {
              id
              individualHealthcareIdentifier {
                id
                status
                recordStatus
                ihi
                updatedAt
              }
            }
          }
        }
      `,
      {
        onCompleted: () =>
          showNotification({ type: 'success', message: 'IHI Data Refreshed' }),
        onError: (errors) => {
          showNotification({
            type: 'error',
            message: errors.graphQLErrors[0].message,
          });
          refetchCustomer();
        },
      },
    );

  const [archiveIhiMutation, { loading: loadingArchiveIhi }] = useMutation<
    ArchiveIhiMutation,
    ArchiveIhiMutationVariables
  >(
    gql`
      mutation ArchiveIhi($patientId: String!, $ihi: String!) {
        archiveIhi(patientId: $patientId, ihi: $ihi) {
          user {
            id
            individualHealthcareIdentifier {
              id
            }
          }
        }
      }
    `,
    {
      onCompleted: () =>
        showNotification({ type: 'success', message: 'IHI Data Archived' }),
      onError: (errors) => {
        showNotification({
          type: 'error',
          message: errors.graphQLErrors[0].message,
        });
        refetchCustomer();
      },
    },
  );

  const handleRefresh = (): void => {
    if (customer) {
      revalidateIhiMutation({
        variables: {
          input: {
            customerId: customer.id,
          },
        },
      });
    }
  };

  const openConfirmModal = (): void => {
    setShowConfirm(true);
  };

  const handleArchiveIhi = (): void => {
    if (customer && customer.individualHealthcareIdentifier?.ihi) {
      archiveIhiMutation({
        variables: {
          patientId: customer.id,
          ihi: customer.individualHealthcareIdentifier?.ihi,
        },
      });
    }
  };

  return (
    <div className="overflow-hidden my-4 rounded shadow bg-white">
      <div className="px-4 py-5 border-b border-slate-200 flex items-center justify-between">
        <h3 className="text-lg leading-6 font-medium text-slate-900">
          Individual Healthcare Identifier (IHI) Details{' '}
        </h3>
        <button
          className="w-4 h-4"
          onClick={handleRefresh}
          disabled={loadingRevalidateIhi || loadingArchiveIhi}
        >
          <FaRedo className="cursor-pointer" />
        </button>
      </div>
      {loadingRevalidateIhi || loadingArchiveIhi ? (
        <Loading />
      ) : customer?.individualHealthcareIdentifier ? (
        <div>
          <dl>
            <div className="bg-slate-100 px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm leading-5 text-slate-500">IHI</dt>
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
                {customer.individualHealthcareIdentifier?.ihi}
              </dd>
            </div>
            <div className="bg-white px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm leading-5 text-slate-500">Status</dt>
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
                {customer.individualHealthcareIdentifier?.status}
              </dd>
            </div>
            <div className="bg-slate-100 px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm leading-5 text-slate-500">
                Record Status
              </dt>
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
                {customer.individualHealthcareIdentifier?.recordStatus}
              </dd>
            </div>
            <div className="bg-white px-4 py-3 flex items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm leading-5 text-slate-500">
                Last updated at
              </dt>
              <dd className="text-sm leading-5 text-slate-900 sm:mt-0 sm:col-span-2">
                {formatDateAndTimeSeconds(
                  new Date(customer.individualHealthcareIdentifier?.updatedAt),
                )}
              </dd>
            </div>
          </dl>

          <div className="flex justify-end p-4">
            <div className="w-72">
              <ProtectedButton
                variant="outline"
                color="danger"
                size="small"
                requiredPermissions={['EDIT_PATIENTS']}
                onClick={openConfirmModal}
              >
                Archive IHI
              </ProtectedButton>
              {showConfirm && (
                <Modal
                  show
                  width="w-96"
                  onClose={(): void => {
                    setShowConfirm(false);
                  }}
                >
                  <div className="bg-white rounded p-4 space-y-4">
                    <p className="font-bold mb-3">
                      Are you sure you want to archive this patient&apos;s IHI?
                    </p>
                    <hr />
                    <p>
                      This action will permanently archive the patient&apos;s
                      IHI. Do not proceed if the patient hasn&apos;t consented
                      to this action.
                      <br />
                      <span className="text-red-500 font-bold">
                        This action cannot be undone.
                      </span>
                    </p>
                    <div className="flex gap-x-4 bg-slate-100">
                      <Button
                        fullWidth
                        autoFocus={true}
                        variant="outline"
                        onClick={(): void => {
                          setShowConfirm(false);
                        }}
                      >
                        Go back
                      </Button>
                      <Button
                        fullWidth
                        color="danger"
                        onClick={handleArchiveIhi}
                      >
                        Yes, archive
                      </Button>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>
        </div>
      ) : (
        <span className="px-4 py-3">No IHI Data</span>
      )}
    </div>
  );
};
